.inputContainer {
  @apply relative;
}

.input {
  @apply block w-full text-gray px-3 py-3.5 tracking-1/4 rounded border border-secondary-gray outline-none transition-colors focus:border-primary;
}

.inputLabel {
  @apply text-gray font-bold text-caption mb-3;
}

.input::placeholder {
  @apply text-secondary-gray select-none;
}

.inputWithPrepend {
  @apply pl-11;
}

.inputWithAppend {
  @apply pr-11;
}

.inputError {
  @apply border-error !important;
}

.inputPrepend {
  @apply absolute left-0 top-1/2 transform -translate-y-1/2 ml-4 text-secondary-gray pointer-events-none;
}

.inputAppend {
  @apply absolute right-0 top-1/2 transform -translate-y-1/2 mr-4 text-secondary-gray pointer-events-none;
}

.fontMedium {
  @apply text-subtitle3;
}

.fontLarge {
  @apply text-subtitle1;
}

.inputDisabled {
  @apply border-line-gray cursor-not-allowed;
}

.inputFilled {
  @apply bg-fill-gray border-transparent;
}
