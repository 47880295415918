.inputContainer {
  @apply relative;
}

.inputWrapper {
  @apply relative flex items-center w-full border-b border-secondary-gray;
}

.input {
  @apply block w-full text-subtitle3 text-gray py-2 tracking-1/4 outline-none;
}

.input::placeholder {
  @apply text-secondary-gray select-none;
}

.inputWithPrepend {
  @apply ml-2;
}

.inputWithAppend {
  @apply mr-2;
}

.inputError {
  @apply border-error !important;
}

.inputPrepend {
  @apply relative left-0 text-gray pointer-events-none;
}

.inputAppend {
  @apply relative right-0 text-gray pointer-events-none;
}

.inputDisabled {
  @apply bg-fill-gray border-line-gray cursor-not-allowed;
}

.fontMedium {
  @apply text-subtitle3;
}

.fontLarge {
  @apply text-subtitle1;
}
